<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-menu
      v-model="showCalendar"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateLabel"
          :label="placeholder"
          append-icon="mdi-calendar"
          :disabled="disabled"
          readonly
          v-bind="attrs"
          hide-details
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="datePicker"
        color="primary"
        :locale="$i18n.locale"
        :min="minDate"
        :max="maxDate"
        @input="showCalendar = false"
      />
    </v-menu>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

export default {
  name: 'DatePicker',
  props: {
    placeholder: { type: String, default: '' },
    date: { type: String, default: '' },
    max: { type: String, default: undefined },
    min: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    showCalendar: false,
  }),
  computed: {
    datePicker: {
      get() {
        return this.dateToIsoFormat(this.date ? new Date(this.date) : new Date());
      },
      set(newDate) {
        this.$emit('input', this.$moment(newDate).toISOString());
      },
    },
    dateLabel() {
      if (this.date) {
        const date = this.$moment(this.date);

        if (date.isValid()) {
          return date.format('DD/MM/YYYY');
        }
      }

      return '';
    },
    minDate() {
      return this.min ? this.dateToIsoFormat(new Date(this.min)) : undefined;
    },
    maxDate() {
      return this.dateToIsoFormat(this.max ? new Date(this.max) : new Date());
    },
  },
  methods: {
    dateToIsoFormat(date) {
      // v-date-picker expects date in YYYY-MM-DD format. Because of the user locale, we can not
      // 'just use' ISOFormat
      let month = `${date.getMonth() + 1}`;
      let day = `${date.getDate()}`;
      const year = date.getFullYear();

      if (month.length < 2) { month = `0${month}`; }

      if (day.length < 2) { day = `0${day}`; }

      return [year, month, day].join('-');
    },
  },
};
</script>
