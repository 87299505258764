<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="units.items"
      :server-items-length="units.total"
      :loading="isLoading"
      hide-default-footer
      @update:page="onPageNumberUpdated"
    >
      <template v-slot:header>
        <div
          class="pa-3 pb-4 d-flex justify-space-between align-center"
          style="border-bottom: 1px solid;"
          :style="{
            borderColor: $vuetify.theme.themes.light.border,
          }"
        >
          <div
            class="d-flex align-center"
            style="flex: 1"
          >
            <input-text
              v-model="search"
              max-width="440px"
              left-icon="mdi-magnify"
              :placeholder="$t('deconve.search')"
            />
            <v-menu
              :close-on-content-click="false"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="hasFilterChanged? 'primary' : 'neutral'"
                  :class="hasFilterChanged? 'primaryHighlight' : 'white'"
                  :small="$vuetify.breakpoint.mobile"
                  v-bind="attrs"
                  class="mx-2"
                  v-on="on"
                >
                  <v-icon>
                    {{ hasFilterChanged? icons.mdiFilter: icons.mdiFilterOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <units-filter
                v-model="options"
                :has-changed="hasFilterChanged"
                @change="onFilterChanged"
                @clear="clearFilters"
              />
            </v-menu>
          </div>
          <v-btn
            color="primary"
            elevation="0"
            :disabled="!$can('create', 'com.deconve.unit')"
            :small="$vuetify.breakpoint.mobile"
            @click="onAddUnit()"
          >
            <v-icon :left="!$vuetify.breakpoint.mobile">
              {{ icons.addUnit }}
            </v-icon>

            <div v-if="!$vuetify.breakpoint.mobile">
              {{ $t('deconve.addUnit') }}
            </div>
          </v-btn>
        </div>
      </template>

      <template v-slot:loading>
        <span
          class="pa-2 ml-1"
        >
          {{ $t('deconve.loading') }}
        </span>
      </template>

      <template v-slot:no-data>
        <span
          class="ml-1 pa-2 neutralPrimary--text text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
        >
          {{ $t('deconve.noUnit') }}
        </span>
      </template>

      <template v-slot:default="props">
        <div
          style="flex: 1; overflowY: auto;"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.background,
          }"
        >
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />
          <div class="pa-2">
            <div
              class="d-flex flex-wrap"
            >
              <v-col
                v-for="item in props.items"
                :key="item.id"
                class="pa-1"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                :data-cy="item.id"
              >
                <unit-preview
                  :id="item.id"
                />
              </v-col>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer="props">
        <footer-data-iterator
          :items-per-page-array="itemsPerPageArray"
          :items-per-page="itemsPerPage"
          :page="page"
          :total-data="props.pagination.itemsLength"
          @changeItensPerPage="updateItemsPerPage"
          @changePage="onPageNumberUpdated"
        />
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

import {
  mdiChevronDown, mdiFilter, mdiFilterOutline, mdiPlus,
} from '@mdi/js';

import InputText from '@/components/InputText.vue';
import FooterDataIterator from '@/components/FooterDataIterator.vue';

import { SORT_ORDER } from '@/utils/faceidFilters';
import { PLAYER_STATUS } from '@/store/players';

import UnitsFilter from './UnitsFilter.vue';
import UnitPreview from './UnitPreview.vue';

export default {
  name: 'Units',
  components: {
    InputText,
    UnitPreview,
    UnitsFilter,
    FooterDataIterator,
  },
  data() {
    return {
      itemsPerPageArray: [4, 8, 12, 16],
      search: undefined,
      isLoading: false,
      page: 1,
      itemsPerPage: 12,
      options: {
        sortOrder: SORT_ORDER.ascending,
        status: PLAYER_STATUS.all,
        createdAt: '',
        noTags: false,
        sortBy: 'name',
        tags: [],
        workspaceId: undefined,
        workspaceName: undefined,
      },
      icons: {
        mdiChevronDown,
        mdiFilter,
        mdiFilterOutline,
        addUnit: mdiPlus,
      },
    };
  },
  computed: {
    ...mapGetters({
      units: 'units/unitsPage',
      dataIteratorSettings: 'units/unitsIteratorSettings',
    }),
    hasFilterChanged() {
      return Boolean(
        this.options.sortBy !== 'name'
        || this.options.sortOrder !== SORT_ORDER.ascending
        || this.options.status !== PLAYER_STATUS.all
        || this.options.createdAt
        || this.options.tags.length > 0
        || this.options.noTags
        || this.options.workspaceId
        || this.search,
      );
    },
  },
  watch: {
    search() {
      this.onFilterChanged();
    },
  },
  created() {
    this.getDataIteratorInfo();
    this.onFilterChanged();
  },
  methods: {
    ...mapActions({
      fetchUnitsPage: 'units/fetchUnitsPage',
      saveUnitsIteratorSettings: 'units/setUnitsIteratorSettings',
    }),
    clearFilters() {
      this.options = {
        sortOrder: SORT_ORDER.ascending,
        createdAt: '',
        noTags: false,
        status: PLAYER_STATUS.all,
        sortBy: 'name',
        tags: [],
        workspaceId: undefined,
        workspaceName: undefined,
      };

      this.search = '';
      this.page = 1;

      this.onFilterChanged();
    },
    getDataIteratorInfo() {
      if (this.dataIteratorSettings) {
        const {
          page,
          search,
          sortOrder,
          itemsPerPage,
          sortBy,
          createdAfter,
          status,
          tags,
          noTags,
          workspaceId,
          workspaceName,
        } = this.dataIteratorSettings;

        // Data-iterator updates the page value if it is greater than the number of pages availables
        if (page) {
          this.options = {
            sortOrder,
            sortBy,
            tags,
            noTags,
            workspaceId,
            workspaceName,
            status,
            createdAt: createdAfter,
          };

          this.itemsPerPage = itemsPerPage;
          this.search = search;
          this.page = page;
        }
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.onFilterChanged();
    },
    onPageNumberUpdated(page) {
      this.page = page;
      this.onFilterChanged();
    },
    saveIteratorSettings() {
      const {
        createdAt, sortOrder, sortBy, tags, noTags,
        status, workspaceId, workspaceName,
      } = this.options;

      let createdAfter = '';
      let createdBefore = '';

      if (createdAt) {
        const createdAtMoment = this.$moment(createdAt);

        createdAfter = createdAtMoment.startOf('day').toISOString();
        createdBefore = createdAtMoment.endOf('day').toISOString();
      }

      const dataIterator = {
        createdAfter,
        createdBefore,
        sortOrder,
        sortBy,
        status,
        tags,
        noTags,
        workspaceId,
        workspaceName,
        page: this.page,
        search: this.search,
        itemsPerPage: this.itemsPerPage,
      };

      this.saveUnitsIteratorSettings(dataIterator);
    },
    handleFetchUnits() {
      this.isLoading = true;

      this.fetchUnitsPage().then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onFilterChanged() {
      this.saveIteratorSettings();
      this.handleFetchUnits();
    },
    onAddUnit() {
      this.$router.push({ name: 'unit-register' });
    },
  },
};
</script>
